<template>
	<teleport to="body">
		<div id="menu-bancard-register" class="modal" tabindex="-1" aria-labelledby="modal_example" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
				<div class="modal-content shadow-3 overflow-hidden">
					<div class="modal-header">
						<h5 class="modal-title">Registrar tarjeta</h5>
						<div class="text-xs ms-auto">
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
					</div>
					<div class="modal-body p-0" v-if="!formLoaded">
						<common-message-loading-small class="py-6 justify-content-center" text="Iniciando formulario" />
					</div>
					<div id="bancard-iframe-container" class="modal-body p-0" v-show="formLoaded"></div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import store from '@/store';
	import { Modal } from 'bootstrap';
	import { useToast } from 'vue-toastification';
	import StoreRepository from '@/repositories/StoreRepository';
	import composableForm from '@/composables/form';

	export default {
		emits: ['success'],
		props: {
			constraints: Object
		},
		setup(props, { emit }) {
			const { catchError } = composableForm();
			const modal = ref(null);
			const toast = useToast();
			const formLoaded = ref(false);

			onMounted(() => {
				modal.value = new Modal(document.getElementById('menu-bancard-register'));

				document.getElementById(`menu-bancard-register`).addEventListener('shown.bs.modal', () => {
					if(formLoaded.value) return;

					console.log('constraints', props.constraints.config.environment);

					StoreRepository.cardsCreate(store.getters['cart/slug'], { gateway: props.constraints.gateway })
						.then((response) => {
							var script = document.createElement('script');
								script.src = `https://vpos.infonet.com.py${(props.constraints.config.environment == 'production') ? '' : ':8888'}/checkout/javascript/dist/bancard-checkout-3.0.0.js`;
								script.async = true;

							script.onload = async () => {
								window.Bancard.Cards.createForm('bancard-iframe-container', response.data.process_id, {
									styles: {
										'button-background-color': '#111827',
										'button-text-color': '#FFF',
										'button-border-color': '#111827'
									},
									responseHandler: (response) => {
										switch(response.message) {
											case 'add_new_card_success':
												toast.success(`Tarjeta cargada correctamente.`);
												emit('success');
												modal.value.hide();
												break;
											default:
												if(response.details) toast.error(response.details);
												else toast.error(`Error al registrar la tarjeta (${response.message}).`);
												break;
										}
									}
								});

								formLoaded.value = true;
							};

							document.head.appendChild(script);
						}).catch((e) => {
							catchError(e, true);
							modal.value.hide();
						});
				});
			});

			return { formLoaded, modal };
		}
	}
</script>

<style>
	#bancard-iframe-container iframe {
		height: 100% !important;
		padding: 1.5rem !important;
	}
</style>